<div class="picture-grid-wrapper">
	<div class="picture-grid">
		<div
			*ngIf="!noGrid"
			layout="column"
			class="cells"
			[ngClass]="{
				'fullscreen-highlight': openFullscreen
			}"
			[ngStyle]="{
				'-webkit-tap-highlight-color': rippleColor + 'ab'
			}"
		>
			<div
				*ngFor="let row of rows; index as x"
				layout="row"
				flex
				class="row"
				[ngStyle]="{
					'color': rippleColor
				}"
			>
				<div
					*ngFor="let column of columns; index as y"
					class="cell"
					flex
					matRipple
					[matRippleDisabled]="openFullscreen || cellIsEneabled(x, y) || formlyFieldGridStateIsDisabled"
					[matRippleColor]="rippleColor"
					[ngStyle]="{
						'color': rippleColor,
						'border-color': formlyFieldDataContent.color
					}"
					[ngClass]="{
						enabled: cellIsEneabled(x, y),
						'is-click-disabled': formlyFieldGridStateIsDisabled
					}"
					(click)="toggleCell(x, y)"
				></div>
			</div>
		</div>
		<img [src]="formlyFieldDataContent.image" />
	</div>
</div>
